<script>
export default {
  setup() {
    localStorage.removeItem("jwt");
    localStorage.removeItem("user");
    localStorage.removeItem("per");
    window.location = "/login";
  },
};
</script>
